*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: auto;
    font-family: 'Comfortaa', sans-serif;
    font-family: 'DM Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

html{
    scroll-behavior: smooth;
}
