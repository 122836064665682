@media only screen and (min-width: 500px) {
  body {
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  .headcontact {
    height: 4rem;
    background-color: #583822;
    display: flex;
    /* align-items: flex-end; */
    justify-content: space-between;
    padding: 10px;
  }

  .head_btn {
    height: 3rem;
    width: 8rem;
    background-color: #f6eedd;
    color: #583822;
    border: none;
    border-radius: 4px;
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }

  .navbody {
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ul {
    text-decoration: none;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
  }

  .li {
    text-decoration: none;
    list-style: none;
  }

  .img_one {
    /* background-image: url('../../Assets/Images/floor.png'); */

    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor.png");
    background-size: contain;
    background-repeat: repeat;
    width: 100vw;
    height: 100%;
    
  }

  
  .img_two {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor2.jpg");
    background-size: contain;
    background-repeat: repeat;
    width: 100vw;
    height: 100%;
  }

  .img_three {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor3.png");
    background-size: contain;
    background-repeat: repeat;
    width: 100vw;
    height: 100%;
  }

  .img_four {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/fllor3.jpg");
    background-size: contain;
    background-repeat: repeat;
    width: auto;
    height: 100%;
  }

  .img_four, .img_one,.img_three,.img_two{
    height: 70vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }



}



@media screen and (max-width: 500px) {
    *{
        font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
    }
  body {
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  .headcontact {
    height: 4rem;
    background-color: #583822;
    display: flex;
    /* align-items: flex-end; */
    justify-content: space-between;
    padding: 10px;
  }

  .head_btn {
    height: 2rem;
    width: 3rem;
    background-color: #f6eedd;
    color: #583822;
    border: none;
    border-radius: 4px;
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
    font-weight: 5px;
    margin-top: 1rem;
  }
  .cta {
    display: none;
  }

  .navbody {
    height: 4rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ul {
    text-decoration: none;
    display: flex;
    gap: 2rem;
    justify-content: space-around;
  }

  .li {
    text-decoration: none;
    list-style: none;
  }

  .img_one {
    /* background-image: url('../../Assets/Images/floor.png'); */

    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    height: 50vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .img_two {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    height: 50vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .img_three {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/floor3.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    height: 50vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .img_four {
    background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 0.6) 0%,
        rgba(0, 0, 0, 0.281) 100%
      ),
      url("../../Assets/Images/fllor3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 100%;
    height: 50vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
  }

h1{
    font-size: 1rem;
    font-family: "Comfortaa", sans-serif;
    font-family: "DM Sans", sans-serif;
    font-family: "Roboto", sans-serif;
}
  .content_style {
    height: 70vh;
    color: #fff;
    line-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: end;
  }
}
