@media only screen and (min-width: 500px) {
    .body{
        height: 90vh;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 2rem;
        
    }
    .container{
        height: auto;
        display: flex;
    }
    .h1{
        text-align: center;
        font-size: 2.5rem;
        margin-top: 2rem;
    }
    .p{
        text-align: center;
        width: auto;
        margin: 0 auto;
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .customer{
        height: 50vh;
        width: 100vw;
    }

    .testimony{
        display: flex;
        height: 40vh;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .test_box{
        width: 30vw;
        height: 80%;
        background-color: #a9a9a967;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .test_text{
        display: flex;
        flex-direction: column;
    }
    .username{
        font-weight: bold;
        color: #583822;
        margin-bottom: 1rem;
        
    }
   

}

@media only screen and (max-width: 500px) {
    .body{
        height: auto;
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        
    }
    .container{
        height: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .h1{
        text-align: center;
        font-size: 1.5rem;
        margin-top: 1rem;
    }
    .p{
        text-align: center;
        width: auto;
        margin: 0 auto;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .customer{
        height: 20rem;
        width: 100vw;
    }

    .testimony{
        display: flex;
        flex-wrap: wrap;
        height: auto;
        gap: 1rem;
        margin-top: 1rem;
    }

    .test_box{
        width: 90vw;
        height: 80%;
        background-color: #a9a9a967;
        border-radius: 20px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        padding: 10px;
    }
    .test_text{
        display: flex;
        flex-direction: column;
    }
    .username{
        font-weight: bold;
        color: #583822;
        margin-bottom: 1rem;
        
    }
   
   
}