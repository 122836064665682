@media only screen and (min-width: 500px) {
    .body{
        height: 60vh;
        background-color: #F6EEDD;
        display: grid;
        grid-template-rows: 90% 10%;

    }

    .top{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .logo{
        width: 8rem;
        background-color: #F6EEDD;
        border: 1px solid #583822;
        margin-left: 2rem;
        margin-top: 2rem;
        border-radius: 5px;
    }
    
    .line{
        height: 1px;
        background-color: black;
        width: 90vw;
        margin: 0 auto;
    }
    .ul {
        text-decoration: none;
        

        
      }
      .p{
        width: 30vw;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      .icons{
        display: flex;
        gap: 1rem;
      }
    
      .li {
        text-decoration: none;
        list-style: none;
        font-size: 1.1rem;
        margin-top: 0.5rem;
      }

    .foot{
        display: flex;
        justify-content: space-around;
        
    }

    .bottom{
        
        display: flex;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 500px) {
    .body{
        height: auto;
        background-color: #F6EEDD;
        display: grid;
        grid-template-rows: 90% 10%;

    }

    .top{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        
    }

    .logo{
        width: 8rem;
        background-color: #F6EEDD;
        border: 1px solid #583822;
        margin-left: 2rem;
        margin-top: 2rem;
        border-radius: 5px;
    }
    
    .line{
        height: 1px;
        background-color: black;
        width: 90vw;
        margin: 0 auto;
        margin-top: 1rem;
    }
    .ul {
        text-decoration: none;
        
      }
      .p{
        margin-top: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .icons{
        display: flex;
        gap: 1rem;
      }
    
      .li {
        text-decoration: none;
        list-style: none;
        font-size: 1rem;
      }

    .foot{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        
    }

    .bottom{
        
        display: flex;
        justify-content: space-around;
    }
}